<template>
    <div class="form-card">
        <div class="row">
            <div class="col-md-12 col-lg-12 col-xl-6">
                <panel id="panel-1">
                    <template v-slot:header>
                        <h2>
                            <span class="icon-stack fs-xxl mr-2">
                                <i
                                    class="base base-7 icon-stack-4x opacity-100 color-primary-700"
                                ></i>
                                <i
                                    class="fal fa-user icon-stack-2x opacity-100 color-white"
                                ></i>
                            </span>
                            {{ $t('post.page.information.general') }}
                        </h2>
                    </template>

                    <div class="panel-container collapse show" style="">
                        <div class="panel-content">
                            <ul
                                class="nav nav-tabs border-bottom-0"
                                role="tablist"
                            >
                                <li class="nav-item">
                                    <a
                                        class="nav-link active"
                                        data-toggle="tab"
                                        href="#lang-fr"
                                        role="tab"
                                    >
                                        Français 🇫🇷
                                    </a>
                                </li>
                                <li class="nav-item">
                                    <a
                                        class="nav-link"
                                        data-toggle="tab"
                                        href="#lang-en"
                                        role="tab"
                                    >
                                        English 🇺🇸
                                    </a>
                                </li>
                            </ul>

                            <div class="tab-content">
                                <div
                                    class="tab-pane fade show active"
                                    id="lang-fr"
                                    role="tabpanel"
                                >
                                    <div class="form-group">
                                        <label
                                            class="form-label"
                                            for="form-name-fr"
                                            >{{
                                                $t(
                                                    'post.page.preview.form_name'
                                                )
                                            }}</label
                                        >
                                        <input
                                            id="form-name-fr"
                                            v-model="form.fr.name"
                                            class="form-control"
                                            type="text"
                                        />
                                    </div>

                                    <div class="form-group">
                                        <label
                                            class="form-label"
                                            for="form-description-fr"
                                            >{{
                                                $t(
                                                    'post.page.preview.description'
                                                )
                                            }}</label
                                        >
                                        <ckeditor
                                            v-model="form.fr.description"
                                            :config="editorConfig"
                                            :editor="editor"
                                        ></ckeditor>
                                    </div>
                                </div>
                                <div
                                    class="tab-pane fade"
                                    id="lang-en"
                                    role="tabpanel"
                                >
                                    <div class="form-group">
                                        <label
                                            class="form-label"
                                            for="form-name-en"
                                            >{{
                                                $t(
                                                    'post.page.preview.form_name'
                                                )
                                            }}</label
                                        >
                                        <input
                                            id="form-name-en"
                                            v-model="form.en.name"
                                            class="form-control"
                                            type="text"
                                        />
                                    </div>

                                    <div class="form-group">
                                        <label
                                            class="form-label"
                                            for="form-description-en"
                                            >{{
                                                $t(
                                                    'post.page.preview.description'
                                                )
                                            }}</label
                                        >
                                        <ckeditor
                                            v-model="form.en.description"
                                            :config="editorConfig"
                                            :editor="editor"
                                        ></ckeditor>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <template v-slot:footer> </template>
                </panel>
            </div>

            <div class="col-md-12 col-lg-12 col-xl-6">
                <FormRacisForm ref="formRacisForm" :editform="form" />
            </div>
        </div>
    </div>
</template>

<style>
.ck-editor__editable_inline {
    min-height: 300px;
}

.vs__dropdown-option--highlight {
    background-color: #eee;
    transform: scale(1.03);
}

.vs__dropdown-menu {
    overflow-x: hidden;
}
</style>

<script>
import Panel from '@/components/UI/Panel'
import { mapActions, mapState } from 'vuex'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import FormRacisForm from '@/components/Model/Form/FormComponents/FormRacisForm.vue'

export default {
    name: 'Step1',
    props: {},
    components: {
        Panel,
        FormRacisForm
    },
    watch: {},
    data: function () {
        return {
            //ckeditor
            editor: ClassicEditor,
            editorConfig: {
                toolbar: {
                    items: [
                        'bold',
                        'italic',
                        'link',
                        '|',
                        'bulletedList',
                        'numberedList',
                        '|',
                        'undo',
                        'redo'
                    ]
                }
            }
        }
    },
    created() {},
    computed: {
        ...mapState('form', {
            form: (state) => state.newForm
        })
    },
    methods: {
        ...mapActions('form', { editForm: 'editFormRaciBuilder' }),
        updatedata() {
            this.$refs.formRacisForm
                .update()
                .catch((e) => {
                    console.log(e)
                    this.$store.dispatch('dispatchError', e)
                    this.loading = true
                })
                .finally(() => {
                    this.loading = false
                })
        }
    },
    mounted() {}
}
</script>
