<style scoped>
#msform {
    text-align: center;
    position: relative;
}

#msform fieldset .form-card {
    border: 0 none;
    border-radius: 0px;
    position: relative;
}

#msform fieldset {
    border: 0 none;
    border-radius: 0.5rem;
    width: 100%;
    margin: 0;
    padding-bottom: 20px;
    position: relative;
}

#msform fieldset:not(:first-of-type) {
    display: none;
}

#msform fieldset .form-card {
    text-align: left;
    color: #9e9e9e;
}

select.list-dt {
    border: none;
    outline: 0;
    border-bottom: 1px solid #ccc;
}

select.list-dt:focus {
    border-bottom: 2px solid var(--theme-primary);
}

.card {
    border: none;
    border-radius: 0.5rem;
    position: relative;
}

.fs-title {
    font-size: 25px;
    color: #2c3e50;
    margin-bottom: 10px;
    font-weight: bold;
    text-align: left;
}

ul {
    padding-inline-start: 0px;
}

#progressbar {
    position: relative;
    margin-bottom: 30px;
    overflow: hidden;
    color: lightgrey;
}

#progressbar .active {
    color: #000000;
}

#progressbar li {
    list-style-type: none;
    font-size: 12px;
    width: 33%;
    float: left;
    position: relative;
}

#progressbar #account:before {
    content: '\f007';
}

#progressbar #personal:before {
    content: '\f681';
}

#progressbar #payment:before {
    content: '\f0c7';
}

#progressbar li:before {
    width: 50px;
    height: 50px;
    line-height: 45px;
    display: block;
    font-size: 18px;
    color: #ffffff;
    background: lightgray;
    border-radius: 50%;
    margin: 0 auto 10px auto;
    padding: 2px;
    font-family: 'Font Awesome 5 Pro';
}

#progressbar li:after {
    content: '';
    width: 100%;
    height: 2px;
    background: lightgray;
    position: absolute;
    display: block;
    left: 0;
    top: 25px;
}

#progressbar li.active:before,
#progressbar li.active:after {
    background: var(--theme-primary);
}

.slide-fade-enter-active {
    transition: all 0.6s ease;
}

.slide-fade-leave-active {
    transition: all 0.4s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter, .slide-fade-leave-to
    /* .slide-fade-leave-active below version 2.1.8 */ {
    transform: translateX(10px);
    opacity: 0;
}

.svd-vertical-container__row {
    text-align: left;
}
ul .navbar-default .container-fluid {
    display: none;
}
</style>

<template>
    <main class="page-content" role="main">
        <div class="subheader hidden-lg-up">
            <portal to="ariane">
                <h1 class="subheader-title">
                    <i class="fal fa-poll"></i> {{ $t('forms.new') }}
                    <small>{{ $t('forms.form_builder') }}</small>
                </h1>
            </portal>
        </div>

        <div class="container-fluid">
            <div
                class="spinner-border text-primary"
                role="status"
                v-if="loading"
            >
                <span class="sr-only">{{ $t('forms.loading') }}...</span>
            </div>

            <div class="row justify-content-center mt-0">
                <div class="col-md-12 text-center p-0 mt-3 mb-2">
                    <ul id="progressbar">
                        <li id="account" class="active">
                            <a href="#" @click.prevent="setStep(1)">
                                <strong>{{ $t('forms.form') }}</strong>
                            </a>
                        </li>
                        <li id="personal" :class="[step >= 2 ? 'active' : '']">
                            <a href="#" @click.prevent="setStep(2)">
                                <strong>{{
                                    $t('forms.steps_attributes')
                                }}</strong>
                            </a>
                        </li>
                        <li id="payment" :class="[step >= 3 ? 'active' : '']">
                            <a href="#" @click.prevent="setStep(3)">
                                <strong>{{ $t('forms.save') }}</strong>
                            </a>
                        </li>
                    </ul>

                    <div class="">
                        <h2 v-if="!saved" class="mt-3">
                            <button
                                v-show="step > 1"
                                :disabled="loading"
                                class="mr-5 btn btn-primary"
                                @click.prevent="previous"
                            >
                                <i class="fas fa-angle-left"></i>
                            </button>
                            <strong>{{ $t('forms.new') }}</strong>
                            <button
                                v-show="step < maxStep"
                                :disabled="loading"
                                class="ml-5 btn btn-primary"
                                @click.prevent="next"
                            >
                                <i class="fas fa-angle-right"></i>
                            </button>
                        </h2>

                        <div class="row">
                            <div class="col-md-12">
                                <form id="msform">
                                    <!-- fieldsets -->
                                    <div class="p-5">
                                        <transition
                                            appear
                                            mode="out-in"
                                            name="slide-fade"
                                        >
                                            <fieldset
                                                v-if="step === 1"
                                                key="step1"
                                            >
                                                <step1 ref="step1"></step1>
                                            </fieldset>

                                            <fieldset
                                                v-if="step === 2"
                                                key="step2"
                                                class="text-left"
                                            >
                                                <survey-creator
                                                    ref="surveyjs"
                                                    :survey="survey"
                                                ></survey-creator>
                                            </fieldset>

                                            <fieldset
                                                v-if="step === 3"
                                                key="step3"
                                            >
                                                <step3></step3>
                                            </fieldset>
                                        </transition>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>

                    <div v-if="!saved" class="d-flex">
                        <div class="col text-left">
                            <button
                                v-show="step > 1"
                                :disabled="loading"
                                class="btn btn-secondary"
                                href="#"
                                @click.prevent="previous"
                            >
                                {{ $t('forms.previous') }}
                            </button>
                        </div>
                        <div class="col-auto mr-auto text-right">
                            <button
                                v-show="step < maxStep"
                                :disabled="loading"
                                class="btn btn-primary"
                                href="#"
                                @click.prevent="next"
                            >
                                {{ $t('forms.next') }}
                            </button>
                        </div>
                        <div
                            v-show="step == maxStep"
                            class="col-auto mr-auto text-right"
                        >
                            <button
                                :disabled="canCreate()"
                                class="btn btn-primary"
                                href="#"
                                @click.prevent="doCreate"
                            >
                                {{ $t('forms.save_form') }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import Step1 from '@/components/Model/Form/FormSteps/Step1'
import Step3 from '@/components/Model/Form/FormSteps/Step3'

import SurveyCreator from '@/components/FormBuilder/SurveyCreator.vue'
import BmvSurvey from '@/components/FormBuilder/BmvSurvey'

export default {
    name: 'FormBuilder',
    components: {
        SurveyCreator,
        Step1,
        Step3
    },
    data() {
        return {
            step: 1,
            selectedRole: {},
            maxStep: 2,
            loading: false,
            saved: false,
            manualNavigation: false,
            survey: new BmvSurvey('surveyCreatorContainer', this.$i18n.locale)
        }
    },
    async created() {
        const formTypeIdFor = await this.getStatus().then(
            ({ data }) => data.statuses[0]
        )
        let formId = this.$route.params.formId
        let formTypeId = formTypeIdFor.id
        if (formId) {
            this.loading = true
            this.editForm(formId)
                .then(() => {
                    this.survey.parseForm(this.form)
                })
                .catch((e) => {
                    console.log(e)
                    this.$store.dispatch('dispatchError', e)
                })
                .finally(() => {
                    this.loading = false
                })
        } else {
            this.form.fr = {}
            this.form.en = {}
            if (!formTypeId || formTypeId <= 0) {
                this.$store.dispatch('toast', {
                    type: 'error',
                    title: 'Erreur',
                    message: this.$t('error.validation.form.unselected')
                })
            }
            this.initForm().then(() => {
                this.form.form_type_id = formTypeId
            })
        }
        localStorage.removeItem('survey')
        this.survey.init()
    },
    computed: {
        ...mapState('form', {
            form: (state) => state.newForm
        })
    },
    methods: {
        ...mapActions('form', {
            createForm: 'createForm',
            updateForm: 'updateForm',
            editForm: 'editForm',
            initForm: 'initForm'
        }),
        ...mapActions('status', ['getStatus']),
        next: function () {
            this.form.racis = this.$refs.step1.$refs.formRacisForm.racisresult
            if (!this.saved) {
                switch (this.step) {
                    case 1:
                        if (!this.form.fr.name && !this.form.en.name) {
                            this.showErrorMessage(
                                this.$t('error.validation.form.unselected')
                            )
                            return
                        } else if (!this.form.fr.name) {
                            this.form.fr.name = this.form.en.name
                            this.form.fr.description = this.form.en.description
                            this.step++
                            this.manualNavigation = false
                            return
                        } else if (!this.form.en.name) {
                            this.form.en.name = this.form.fr.name
                            this.form.en.description = this.form.fr.description
                            this.step++
                            this.manualNavigation = false
                            return
                        }
                        break
                    case 2:
                        break
                }

                this.step++
                this.manualNavigation = false
            }
        },
        previous: function () {
            if (!this.saved) {
                if (this.step === 2) {
                    this.survey.saveContent()
                }

                this.step--
                this.manualNavigation = false
            }
        },
        setStep: function (step) {
            if (!this.saved) {
                this.step = step
                this.manualNavigation = true
            }
        },
        doCreate: async function () {
            for (var element in this.form.racis) {
                delete this.form.racis[element].user
            }
            this.loading = true

            let formId = this.$route.params.formId

            let form = await this.survey.toJson()
            this.form.steps = form.steps

            let request
            if (formId) {
                form.id = formId
                request = this.updateForm()
            } else {
                request = this.createForm()
            }

            request
                .then((response) => {
                    this.step++
                })
                .catch((e) => {
                    console.log(e)
                    this.$store.dispatch('dispatchError', e)
                })
                .finally(() => {
                    this.loading = false
                })
        },
        showSuccessMessage: function () {
            this.$store.dispatch('toast', {
                type: 'success',
                title: this.$t('success.title'),
                message: this.$t('success.message')
            })
        },
        showErrorMessage: function (errorMessage) {
            this.$store.dispatch('toast', {
                type: 'error',
                title: this.$t('error.title'),
                message:
                    errorMessage && errorMessage.length > 0
                        ? errorMessage
                        : this.$t('error.validation.retry')
            })
        },
        canCreate: function () {
            return !!this.loading
        }
    }
}
</script>
