<script>
	import { Modal } from "bootstrap";
	export default {
		components: {},
		data: function () {
			return {
				modal: null,
			};
		},
		watch: {},
		created() {},
		computed: {},
		methods: {
			create: function (id, options) {
				this.modal = new Modal(document.getElementById(id), options);
			},
			show: function () {
				if (!this.modal) return;

				this.modal.show();
			},
			toggle: function () {
				if (!this.modal) return;

				this.modal.toggle();
			},
			hide: function () {
				if (!this.modal) return;

				this.modal.hide();
			},
			dispose: function () {
				if (!this.modal) return;

				this.modal.dispose();
			},
		},
		mounted() {},
	};
</script>
